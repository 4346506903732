<template>
    <div> 
        <v-row class="ma-0 pa-0 py-16 px-6">
            <v-col cols="12" xl="8" lg="8" md="12" sm="12" xs="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0" justify="center">
                    <v-row class="ma-0 pa-0 pb-16">
                        <h1 class="display-3 font-weight-black">About Me</h1>
                    </v-row>
                    <v-row class="ma-0 pa-0 body-1">
                        <v-col class="ma-0 pa-0">
                            <p>Hello!</p> 
                            <p>I'm a Senior Fullstack Software Engineer with an entrepreneurial drive to create high-quality, bespoke software for my clients.
                                I'm constantly learning new technologies and am motivated to create reliable products that will advance our technological ecosystem.</p>
                            <p class="pt-8">Some of my recent projects:</p>
                            <p>A maintainer app for U.S. Army autonomous remote combat vehicles that provides real-time hardware diagnoses, vehicle software updates, and post-op telemetry.
                                This AI venture directly protects our soldiers and minimizes loss of life in combat missions.</p>
                            <p>A mobile app solution for proven identity, authentication, and finding missing persons.
                                This solution derives your proven identity from your biometrics such as face, palm and voice. This enables an employee proofing solution that vets potential candidates during the hiring process.</p>
                            <p>A two-factor authentication app that can be used to safely login to any partnered organization.
                                Some supporting projects were a secure note-taking app, a messaging app that blocks spam texts, and a website to track customer traffic.</p>
                            <p class="pt-8">When I'm not learning a new skill, chances are I'm training in the gym, rock climbing, or playing the piano.</p>
                            <p class="pt-14 pr-4 font-italic body-2 text-end">Life before death, strength before weakness, journey before destination.</p>
                            <p class="pr-4 font-italic body-2 text-end">- Brandon Sanderson</p>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="12" sm="12" xs="12" class="ma-0 pa-0" justify="center" align-self="center">
                <v-row class="ma-16" justify="center">
                    <v-card elevation="8" class="ma-0 pa-0">
                        <v-img src="@/assets/me.jpg" contain></v-img>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ma-0 pa-0 pt-10 pb-10" justify="center">
            <h1 class="display-2 font-weight-black">Skills</h1>
        </v-row>
        <v-row class="ma-0 pa-0 body-1 px-10" justify="center">
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">Programming Languages</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills1" :key="i"> {{ skill }}</v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">Technology</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills2" :key="i"> {{ skill }}</v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">AWS (Amazon Web Services)</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills3" :key="i"> {{ skill }}</v-chip>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 body-1 px-10" justify="center">
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">Libraries</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills4" :key="i"> {{ skill }}</v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">Principles</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills5" :key="i"> {{ skill }}</v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-row class="title pb-2 mx-1">Abstract</v-row>
                <v-chip color="#272727" text-color="white" class="ma-1" label v-for="(skill, i) in skills6" :key="i"> {{ skill }}</v-chip>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {    
            skills1: [
                'C#',
                'JavaScript',
                'TypeScript',
                'Swift',
                'Java',
                'Python',
                'HTML',
                'CSS',
                'SQL',
            ],

            skills2: [
                '.NET',
                'Maui',
                'Xamarin',
                'Vue',
                'React',
                'NodeJS',
                'Git',
                'PostgreSQL',
                'MySQL',
                'MS SQL Server',
                'Azure',
                'Jenkins',
                'Docker',
                'Kubernetes',
                'Terraform',
            ],

            skills3: [
                'Lambda',
                'ECR',
                'S3',
                'IAM',
                'SNS',
                'SQS',
                'API Gateway',
                'Route 53',
                'CloudFront',
                'CloudWatch',
                'RDS'
            ],

            skills4: [
                'MaterialUI',
                'Emotion',
                'Axios',
                'Moq',
                'FluentAssertions',
                'FluentValidation',
                'xUnit',
                'Utf8Json',
                'EntityFramework',
            ],

            skills5: [
                'Clean Code',
                'SOLID',
                'RESTful API',
                'Microservices',
                'Agile',
                'CI/CD',
                'TDD',
                'E2E',
                'IaC',
                'Mobile-First Design'
            ],

            skills6: [
                'Innovation',
                'Problem Solving',
                'Focus',
                'Leadership',
                'Comunication',
                'Teamwork',
                'Conflict Resolution',
                'Lifelong Learning'
            ]
        };
    },
}
</script>
